import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import HomePage from "./HomePage"
import PostalPage from "./PostalPage";

import { HashRouter as Router, Switch, Route, Link } from "react-router-dom";

function RoutePage() {

  return (
    <div>

      <div>
        <div>
          <Router>
            <Switch>
              <Route path="/postal/:id/:nombre">
                <PostalPage />
              </Route>
              <Route exact path="/:id">
                <HomePage />
              </Route>
            </Switch>
          </Router>
        </div>
      </div>
    </div>
  );
}

export default RoutePage;
