import React from 'react';
import RoutePage from './RoutePage';

function App() {
  return (
    <RoutePage/>
  );
}

export default App;
