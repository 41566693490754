import { useState, useEffect } from "react";
import fondo from "./assets/postal/fondo_grecas.png"; // Tell webpack this JS file uses this image
import logo from "./assets//postal/logo_postal.png";
import logo_dark from "./assets//postal/logo_postal_dark.png";
import Typography from "@material-ui/core/Typography";
import patrocinador from "./assets/postal/patrocinador.png";
import patrocinador1 from "./assets/postal/patrocinador1.png";
import Grid from "@material-ui/core/Grid";
import personalInfo from "./assets/text/personalInfo.json";
import {
  makeStyles,
} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import data from "./assets/text/text.json";
import { useParams } from "react-router-dom";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import api from "./assets/text/api.json";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import FileCopyIcon from "@material-ui/icons/FileCopy";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200,
  },

  cssLabel: {
    color: "#cd7f00",
  },

  cssOutlinedInput: {
    "&$cssFocused $notchedOutline": {
      borderColor: `#fff !important`,
    },
  },

  cssFocused: {
    color: "#cd7f00",
  },

  notchedOutline: {
    borderWidth: "1px",
    borderColor: "#fff !important",
  },

  button: {
    backgroundColor: "#633954",
    borderRadius: 24,
    height: 30,
    justifyContent: "center",
    alignItems: "center",
    left: "50%",
    transform: "translate(-50%, -50%)",
    position: "absolute",
    borderWidth: "1px",
    borderColor: "#fff",
  },
  buttonContacto: {
    backgroundColor: "#633954",
    borderRadius: 24,
    height: 30,
    justifyContent: "center",
    alignItems: "center",
    left: "50%",
    top: "100%",
    transform: "translate(-50%, -50%)",
    position: "absolute",
    borderWidth: "1px",
    borderColor: "#fff",
  },

  buttonBlanco: {
    height: 40,
    marginTop: 10,
    backgroundColor: "#fff",
  },
}));

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}



function PostalPage() {
  const [espanol, setEspanol] = useState(true);
  const [pressed, setPressed] = useState(false);
  const [nombre2, setNombre2] = useState("");
  const [nombre3, setNombre3] = useState("");
  const [time2, setTime2] = useState("");
  const classes = useStyles();
  const { height, width } = useWindowDimensions();
  const { id, nombre } = useParams();
  const [mode, setMode] = useState('light')

  useEffect(() => {
    // Add listener to update styles
    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', e => onSelectMode(e.matches ? 'dark' : 'light'));

    // Setup dark/dark mode for the first time
    onSelectMode(window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light')

    // Remove listener
    return () => {
      window.matchMedia('(prefers-color-scheme: dark)').removeEventListener('change', () => {
      });
    }
  }, []);

  const onSelectMode = (mode) => {
    setMode(mode)
  }

  useEffect(() => {
    const now = new Date();
    const time = now.getHours();
    if (time >= 4 && time <= 12) {
      setTime2("1");
    } else if (time >= 12 && time <= 19) {
      setTime2("2");
    } else {
      setTime2("3");
    }
  });
  const _handleTextFieldChange = (e) => {
    setNombre2(e.target.value);
    if (e.target.value) {
      setNombre3(e.target.value);
    }
  };

  
  //desktop
  return (
    <div>
      <img
        src={fondo}
        style={{
          position: "fixed",
          width: ((height + 200) * 1920) / 1080,
          height: height + 200,
          backgroundSize: "cover",
        }}
      />
      ;
      <div
        style={{
          backgroundColor: "#fff",
          minWidth: 250,
          minHeight: 600,
          width: width - 70,
          height: height - 150,
          position: "absolute",
          borderRadius: 7,
          left: width - (width - 35),
          right: width - (width - 35),
          top: height - (height - 75),
          bottom: height - (height - 75),
        }}
      >
        <Button
          className={classes.button}
          variant="contained"
          color="#fff"
          onClick={() => setEspanol(!espanol)}
        >
          {width <= 650 ? (
            <Typography
              style={{
                margin: 0,
                fontWeight: "500",
                fontSize: width * 0.03,
                textAlign: "center",
                color: "#fff",
                fontFamily: "OpenSans",
              }}
            >
              {" "}
              {espanol ? data.espanol.traducir : data.maya.traducir}
            </Typography>
          ) : (
            <Typography
              style={{
                margin: 0,
                fontWeight: "500",
                fontSize: 20,
                textAlign: "center",
                color: "#fff",
                fontFamily: "OpenSans",
              }}
            >
              {" "}
              {espanol ? data.espanol.traducir : data.maya.traducir}
            </Typography>
          )}
        </Button>

        {/*Boton contacto*/}
        <Button
          className={classes.buttonContacto}
          variant="contained"
          color="#fff"
          href={"https://www.facebook.com/Kikibamx-102010528805064/?ref=py_c"}
        >
          {width <= 650 ? (
            <Typography
              style={{
                margin: 0,
                fontWeight: "500",
                fontSize: width * 0.03,
                textAlign: "center",
                color: "#fff",
                fontFamily: "OpenSans",
              }}
            >
              {" "}
              {espanol ? data.espanol.contacto : data.maya.contacto}
            </Typography>
          ) : (
            <Typography
              style={{
                margin: 0,
                fontWeight: "500",
                fontSize: 20,
                textAlign: "center",
                color: "#fff",
                fontFamily: "OpenSans",
              }}
            >
              {" "}
              {espanol ? data.espanol.contacto : data.maya.contacto}
            </Typography>
          )}
        </Button>

        {/*Imagen de mujer*/}
        <Grid container justify="center" alignItems="center" spacing={3}>
          <Grid item xs={6}>
            <img
              src={personalInfo[id].imagen}
              style={{
                position: "relative",
                height: 260,
                top: 160,
                left: "50%",
                right: "50%",
                transform: "translate(-50%, -50%)",
              }}
            />
            <hr
              style={{
                position: "relative",
                backgroundColor: "#662C4D",
                borderColor: "#662C4D",
                width: width * 0.7,
                height: 1,
                marginTop: 42,
                left: "50%",
                right: "50%",
                transform: "translate(-50%, -50%)",
              }}
            />
          </Grid>
        </Grid>

        {/*Gracias + nombre*/}
        {width < 430 ? (
          <Grid container spacing={3} justify="space-around">
              <Grid item xs={3}>
                <Typography
                  style={{
                    fontFamily: "Pacifico",
                    fontSize: width * 0.065,
                    color: "#662C4D",
                    lineHeight: 1,
                  }}
                >
                  {espanol ? data.espanol.gracias : data.maya.gracias}{" "}
                </Typography>
                <Typography
                  style={{
                    fontFamily: "Pacifico",
                    fontSize: width * 0.065,
                    color: "#662C4D",
                    lineHeight: 1,
                    marginLeft: 20,
                  }}
                >
                  {nombre}
                </Typography>
              </Grid>
            <Grid item xs={3} container justify="center">
            <div>
              <Typography
                align="center"
                style={{
                  fontFamily: "Montserrat",
                  fontSize: width * 0.025,
                  color: "#4C4C4C",
                  fontWeight: "700",
                  textDecorationLine: "underline",
                  textDecorationColor: "#A898A5",
                  marginRight: 1,
                  marginBottom: 2
                }}
              >
                {espanol
                  ? data.espanol.texto_contenido 
                  : data.maya.texto_contenido}
              </Typography>
              </div>
              {mode === 'light' ? (
              <img style={{ height: width * 0.2 }} src={logo_dark} />
              ):(
                <img style={{ height: width * 0.2 }} src={logo} />
              )}
            </Grid>
          </Grid>
        ) : (
          <Grid container spacing={3} justify="space-around">
            
              <Grid item xs={3}>
              <Typography
                style={{
                  fontFamily: "Pacifico",
                  fontSize: width * 0.04,
                  color: "#662C4D",
                  lineHeight: 1,
                }}
              >
                {espanol ? data.espanol.gracias : data.maya.gracias}{" "}
              </Typography>
              <Typography
                style={{
                  fontFamily: "Pacifico",
                  fontSize: width * 0.04,
                  color: "#662C4D",
                  lineHeight: 1,
                  marginLeft: 20,
                  marginBottom: 5,
                }}
              >
                {nombre}
              </Typography>
            </Grid>
            <Grid item xs={3} container justify="center" style={{display:'inline'}}>
              
              <Typography
                align="justify"
                style={{
                  fontFamily: "Montserrat",
                  fontSize: 12,
                  color: "#4C4C4C",
                  fontWeight: "700",
                  textDecorationLine: "underline",
                  textDecorationColor: "#A898A5",
                  marginRight: 1,
                  marginBottom: 2
                }}
              >
                {espanol
                  ? data.espanol.texto_contenido 
                  : data.maya.texto_contenido}
              </Typography>
              {mode === 'light' ? (
              <img style={{ height: width * 0.08 }} src={logo_dark} />
              ):(
                <img style={{ height: width * 0.08 }} src={logo} />
              )}
            </Grid>
          </Grid>
        )}

        {/*Texto de agradecimiento*/}
        {width < 650 ? (
          <Grid container justify="center" alignItems="center">
            <Grid item xs={9}>
              <div style={{display: "inline-block"}}>
                
                  <Typography
                align="justify"
                style={{
                  fontFamily: "Montserrat",
                  fontSize: width * 0.025,
                  color: "#4C4C4C",
                  fontWeight: "700",
                  marginTop: 10,
                  textDecorationLine: "underline",
                  textDecorationColor: "#A898A5",
                  marginRight: 1
                }}
              >
                {espanol
                  ? data.espanol.texto_contenido3 
                  : data.maya.texto_contenido3}
              </Typography>
                </div>
            </Grid>
          </Grid>
          
        ) : (
          <Grid container justify="center" alignItems="center">
            <Grid item xs={9}>
              <div style={{display: "inline-block"}}>
                
                  <Typography
                align="justify"
                style={{
                  fontFamily: "Montserrat",
                  fontSize: 12,
                  color: "#4C4C4C",
                  fontWeight: "700",
                  marginTop: 10,
                  textDecorationLine: "underline",
                  textDecorationColor: "#A898A5",
                  marginRight: 1
                }}
              >
                {espanol
                  ? data.espanol.texto_contenido3 
                  : data.maya.texto_contenido3}
              </Typography>
              </div>
            </Grid>
          </Grid>
        )}

        {/*Patrocinadores*/}
        {width < 800 ? (
          <Grid container spacing={3} style={{ marginTop: 3 }}>
            <Grid item xs={12} container>
              <Grid item xs={6} container justify="center">
                <Grid item xs={3}>
                  
                    <Typography
                    style={{
                      fontFamily: "Montserrat",
                      fontSize: 10,
                      color: "#4C4C4C",
                      width: 250,
                    }}
                  >
                    {espanol ? data.espanol.apoyo : data.maya.apoyo}
                  </Typography>
                  <img
                    src={patrocinador}
                    style={{
                      height: width * 0.06
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <img
                    src={patrocinador1}
                    style={{
                      height: width * 0.05,
                      marginTop: 18,
                      marginLeft: 15
                    }}
                  />
                </Grid>
              </Grid>
              <Grid item xs={6} container justify="space-evenly">
                <Grid item xs={1}>
                  </Grid>
                <Grid item xs={5} justify="center">
                  <Typography
                    style={{
                      fontFamily: "Montserrat",
                      fontWeight: "700",
                      fontSize: width * 0.025,
                      color: "#662C4D",
                      textAlign: "center",
                      marginBottom: -8,
                    }}
                  >
                    {personalInfo[id].nombre}
                  </Typography>
                  <hr
                    style={{
                      backgroundColor: "#662C4D",
                      borderColor: "#662C4D",
                      height: 0.5,
                      marginBottom: 5,
                    }}
                  />
                  
                    <Typography
                    style={{
                      fontFamily: "Montserrat",
                      fontWeight: "700",
                      fontSize: width * 0.025,
                      color: "#4C4C4C",
                      textAlign: "center",
                      lineHeight: 1,
                    }}
                  >
                    {personalInfo[id].comunidad +
                      ", " +
                      personalInfo[id].estado}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <Grid container spacing={3} style={{ marginTop: 3 }}>
            <Grid item xs={12} container>
              <Grid item xs={6} container justify="center">
                <Grid item xs={3}>
                  
                    <Typography
                    style={{
                      fontFamily: "Montserrat",
                      fontSize: 11,
                      color: "#4C4C4C",
                      width: 250,
                    }}
                  >
                    {espanol ? data.espanol.apoyo : data.maya.apoyo}
                  </Typography>
                  <img
                    src={patrocinador}
                    style={{
                      height: width * 0.03,
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <img
                    src={patrocinador1}
                    style={{
                      height: width * 0.025,
                      marginTop: 18,
                    }}
                  />
                </Grid>
              </Grid>
              <Grid item xs={6} container>
                <Grid item xs={3}>
                  {/*<Paper className={classes.paper}>xs=3</Paper>*/}
                </Grid>
                <Grid item xs={3}>
                  <Typography
                    style={{
                      fontFamily: "Montserrat",
                      fontWeight: "700",
                      fontSize: width * 0.01,
                      color: "#662C4D",
                      textAlign: "center",
                      marginBottom: -8,
                    }}
                  >
                    {personalInfo[id].nombre}
                  </Typography>
                  <hr
                    style={{
                      backgroundColor: "#662C4D",
                      borderColor: "#662C4D",
                      height: 0.01,
                      marginBottom: 5,
                    }}
                  />
                  
                    <Typography
                    style={{
                      fontFamily: "Montserrat",
                      fontWeight: "700",
                      fontSize: width * 0.01,
                      color: "#4C4C4C",
                      textAlign: "center",
                      lineHeight: 1,
                    }}
                  >
                    {personalInfo[id].comunidad +
                      ", " +
                      personalInfo[id].estado}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}

        {/*Compartir*/}
        {width < 650 ? (
          <Grid
            container
            spacing={0}
            direction="row"
            justify="flex-end"
            alignItems="center"
            style={{ position: "absolute", top: "103%" }}
          >
            <Typography
              style={{
                fontFamily: "OpenSans",
                fontWeight: "700",
                fontSize: width * 0.04,
                color: "#fff",
                textAlign: "center",
              }}
            >
              {espanol ? data.espanol.compartir : data.maya.compartir}
            </Typography>
            <WhatsappShareButton
              children={
                <WhatsAppIcon
                  style={{
                    color: "#fff",
                    fontSize: width * 0.08,
                    marginLeft: 10,
                  }}
                />
              }
              url={api.endpoint + id + "/" + nombre}
            />
            <FacebookShareButton
              children={
                <FacebookIcon
                  style={{
                    color: "#fff",
                    fontSize: width * 0.08,
                    marginLeft: 10,
                  }}
                />
              }
              url={api.endpoint + id + "/" + nombre}
            />
            <TwitterShareButton
              children={
                <TwitterIcon
                  style={{
                    color: "#fff",
                    fontSize: width * 0.08,
                    marginLeft: 10,
                  }}
                />
              }
              url={api.endpoint + id + "/" + nombre}
            />
            <Button
              onClick={() => {
                navigator.clipboard.writeText(api.endpoint + id + "/" + nombre);
              }}
            >
              <FileCopyIcon style={{ color: "#fff", fontSize: width * 0.07 }} />
            </Button>
          </Grid>
        ) : (
          <Grid
            container
            spacing={0}
            direction="row"
            justify="flex-end"
            alignItems="center"
            style={{ position: "absolute", top: "100%" }}
          >
            <Typography
              style={{
                fontFamily: "OpenSans",
                fontWeight: "700",
                fontSize: width * 0.02,
                color: "#fff",
                textAlign: "center",
              }}
            >
              {espanol ? data.espanol.compartir : data.maya.compartir}
            </Typography>
            <WhatsappShareButton
              children={
                <WhatsAppIcon
                  style={{
                    color: "#fff",
                    fontSize: width * 0.03,
                    marginLeft: 10,
                  }}
                />
              }
              url={api.endpoint + id + "/" + nombre}
            />
            <FacebookShareButton
              children={
                <FacebookIcon
                  style={{
                    color: "#fff",
                    fontSize: width * 0.03,
                    marginLeft: 10,
                  }}
                />
              }
              url={api.endpoint + id + "/" + nombre}
            />
            <TwitterShareButton
              children={
                <TwitterIcon
                  style={{
                    color: "#fff",
                    fontSize: width * 0.03,
                    marginLeft: 10,
                  }}
                />
              }
              url={api.endpoint + id + "/" + nombre}
            />
            <Button
              onClick={() => {
                navigator.clipboard.writeText(api.endpoint + id + "/" + nombre);
              }}
            >
              <FileCopyIcon
                style={{ color: "#fff", fontSize: width * 0.025 }}
              />
            </Button>
          </Grid>
        )}
      </div>
    </div>
  );
}

export default PostalPage;
