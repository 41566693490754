import { useState, useEffect } from "react";
import fondo from "./assets/fondo_grecas.png"; // Tell webpack this JS file uses this image
import logo from "./assets/logo_principal.png";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Container from "@material-ui/core/Container";
import {
  fade,
  ThemeProvider,
  withStyles,
  makeStyles,
  createMuiTheme,
} from '@material-ui/core/styles';
import Button from "@material-ui/core/Button";
import { HashRouter as Router, Switch, Route, Link } from "react-router-dom";
import data from "./assets/text/text.json";
import { useParams } from "react-router-dom";

import PostalPage from "./PostalPage";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200,
  },

  cssLabel: {
    color : '#cd7f00'
  },

  cssOutlinedInput: {
    '&$cssFocused $notchedOutline': {
      borderColor: `#fff !important`,
    }
  },

  cssFocused: {
    color: '#cd7f00'
  },

  notchedOutline: {
    borderWidth: '1px',
    borderColor: '#fff !important'
  },

  button: {
    backgroundColor: '#633954',
    borderRadius: 24,
    height: 30,
    justifyContent: 'center',
    alignItems: 'center',
    left: '50%',
    transform: "translate(-50%, -50%)",
    position: 'absolute',
    borderWidth: '1px',
    borderColor: '#fff'
  },

  buttonBlanco: {
    height: 40,
    marginTop: 10,
    backgroundColor: '#fff',
  },

}));


function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}


function HomePage() {
  const [espanol,setEspanol] = useState(true);
  const [pressed, setPressed] = useState(false);
  const [nombre2, setNombre2] = useState("")
  const [nombre3, setNombre3] = useState("")
  const [time2, setTime2] = useState("")
  const classes = useStyles();
  const { height, width } = useWindowDimensions();
  const { id } = useParams();
  const [mode, setMode] = useState('light')

  useEffect(() => {
    // Add listener to update styles
    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', e => onSelectMode(e.matches ? 'dark' : 'light'));

    // Setup dark/light mode for the first time
    onSelectMode(window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light')

    // Remove listener
    return () => {
      window.matchMedia('(prefers-color-scheme: dark)').removeEventListener('change', () => {
      });
    }
  }, []);

  const onSelectMode = (mode) => {
    console.log("Modo" , mode)
    setMode(mode)
  }
 

  useEffect(()=>{
    const now = new Date()
    const time = now.getHours()
    console.log("time",time)
    if(time >= 4 && time < 12 ){
      console.log("Buenos días")
      setTime2("1")
    } else if(time >= 12 && time < 19){
      console.log("Buenas tardes")
      setTime2("2")
    } else {
      console.log("Buenas noches")
      setTime2("3")
    }
  })
   const _handleTextFieldChange = (e)=> {
    console.log("nombre2",nombre2)
    setNombre2(e.target.value);
    console.log("nombre2",nombre2)
    if(e.target.value){
      setNombre3(e.target.value)
    }
    }
  //desktop
  return (
    (pressed==false)?
    ( <div>
      <img
        src={fondo}
        style={{
          position: "fixed",
          minWidth: (height * 1920)/1080,
          width: "100%",
          height: height,
          backgroundSize: "cover"
        }}
      />
      ;
      <div
        style={{
          backgroundColor: "#633954",
          minWidth: 250,
          minHeight: 600,
          width: width - 70,
          height: height - 150,
          position: "absolute",
          borderRadius: 7,
          left: width - (width - 35),
          right: width - (width - 35),
          top: height - (height - 75),
          bottom: height - (height - 75)
          
        }}
      >
        <Button className={classes.button} variant="contained" color="#fff" onClick=
        {() => setEspanol(!espanol)}>
          {width <= 650 ? (
          <Typography
          style={{
            margin: 0,
            fontWeight: "500",
            fontSize: width * 0.03,
            textAlign: "center",
            color: '#fff',
            fontFamily: "OpenSans"
          }}
        >
          {" "}
          {espanol? data.espanol.traducir : data.maya.traducir}
          </Typography>
          ):(
          <Typography
          style={{
            margin: 0,
            fontWeight: "500",
            fontSize: 20,
            textAlign: "center",
            color: '#fff',
            fontFamily: "OpenSans"
          }}
        >
          {" "}
          {espanol? data.espanol.traducir : data.maya.traducir}
          </Typography>
          )}
          </Button>
        <Typography
          style={{
            fontSize: 50,
            minWidth: 250,
            textAlign: "center",
            color: "#fff",
            fontFamily: "Pacifico",
            lineHeight: 1,
            marginTop: 30
          }}
        >
          {
            time2 == "1" ? (
              espanol ? data.espanol.buenos_dias : data.maya.buenos_dias
            ): time2 == "2" ? (
              espanol ? data.espanol.buenos_tardes : data.maya.buenos_tardes
            ) : 
             ( espanol ? data.espanol.buenas_noches : data.maya.buenas_noches)
            
          }
        </Typography>
        
        <Typography
          style={{
            fontWeight: "700",
            fontSize: 20,
            minWidth: 250,
            textAlign: "center",
            marginTop: 30,
            color: "#fff",
            fontFamily: "Montserrat",
          }}
        >
          {espanol?data.espanol.mensaje_inicial:data.maya.mensaje_inicial}
        </Typography>
        <Typography
          style={{
            margin: 0,
            fontWeight: "500",
            fontSize: 15,
            minWidth: 250,
            width: width - 70,
            textAlign: "center",
            marginTop: 30,
            color: '#fff',
            fontFamily: "OpenSans"
          }}
        >
          {" "}
          {espanol?data.espanol.nombre:data.maya.nombre}{" "}
        </Typography>
        <div style={{ display: "flex", justifyContent: "center" }}>
        <TextField
          id="standard-name"
          label=""
          className={classes.textField}
          value={nombre2}
          onChange={(e)=>_handleTextFieldChange(e)}
          margin="normal"
          variant="outlined"
          inputProps={{
            maxLength: 10,
            style:{color: "#cd7f00"}
          }}
          InputLabelProps={{
            classes: {
              root: classes.cssLabel,
              focused: classes.cssFocused,
            },
          }}
          InputProps={{
            classes: {
              root: classes.cssOutlinedInput,
              focused: classes.cssFocused,
              notchedOutline: classes.notchedOutline,
            },
            inputMode: "numeric",
          }}
        />
        </div>
        <div
          style={{ display: "flex", justifyContent: "center", marginTop: 20 }}
        >
          <Link
                        style={{ textDecoration: "none" }}
                        to={`/postal/${id}/${nombre3}`}
                      >
                        <Button className={classes.buttonBlanco} variant="contained" color="#fff">
                      
                      
            <Typography
          style={{
            margin: 0,
            fontWeight: "500",
            fontSize: 15,
            textAlign: "center",
            color: '#633954',
            fontFamily: "OpenSans"
          }}
        >
          {" "}
          {espanol?data.espanol.descrubir_mensaje:data.maya.descrubir_mensaje}
          </Typography>

          </Button>
          </Link>
        </div>
      
      <img
        src={logo}
        style={{
          width: 180,
          height: 180,
          borderRadius: 7,
          justifyContent: 'center',
          alignItems: 'center',
          left: '50%',
          top: "97%",
          transform: "translate(-50%, -50%)",
          position: 'absolute',
        }}
      />
      </div>
      </div>)
    :(<PostalPage nombre3={nombre3} />)
    );

}

export default HomePage;
